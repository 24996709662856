import { useState, useEffect } from "react";
import {
  StyledTitle,
  Avatar,
  StyledFormArea,
  colors,
  StyledContainer,
  StyledFormButton,
} from "../components/Styles";
import Logo from "./../assets/liccoLogo.png";
import axios from "axios";
import { useCookies } from "react-cookie";
import { useNavigate } from "react-router-dom";
import M from "materialize-css";

export const Auth = () => {
  useEffect(() => {
    // Initialize Materialize CSS
    localStorage.clear();
    M.AutoInit();
  }, []);

  return (
    <div>
      <StyledContainer>
        <StyledFormArea>
          <Avatar $image={Logo} />
          <StyledTitle color={colors.theme} size={24}>
            Iniciar Sesión
          </StyledTitle>
          <Loginn />
        </StyledFormArea>
      </StyledContainer>
    </div>
  );
};

const Loginn = () => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [, setCookies] = useCookies(["access_token"]);
  const navigate = useNavigate();

  const onSubmit = async (event) => {
    event.preventDefault();
    try {
      const response = await axios.post(
        "https://894bdij9ij.execute-api.us-east-1.amazonaws.com/licco/getlogin",
        {
          user_name: username,
          psswrd: password,
        }
      );

      if (response.data.error_msg === "OK") {
        setCookies("access_token", response.data.token);
        window.localStorage.setItem("role_name", response.data.role_name);
        window.localStorage.setItem("userID", response.data.user_account_id);
        window.localStorage.setItem("username", response.data.email_address);
        window.localStorage.setItem("clientId", response.data.user_account_id);

        //const setDataRole = window.localStorage.setItem("role_name","USER_CORDINADOR");
        //console.log(setDataRole);

        // Procesar el nuevo formato de la lista
        const rawList = response.data.list; // Ahora es un array de objetos
        const idArray = rawList.map((item) => item.idCliente); // Obtener idCliente de cada objeto
        const idsString = idArray.join(",");

        // Guardar datos adicionales en localStorage
        const formattedList = rawList
          .map((item) => `${item.idCliente}-${item.name}`)
          .join(",");
        window.localStorage.setItem("list", idsString);
        window.localStorage.setItem("listRaw", formattedList);

        //id de contrato ejemplo 1084 BACK OFFICE

        // Procesar el nuevo formato de la lista
        const rawListContrato = response.data.list; // Ahora es un array de objetos
        const idArrayContrato = rawListContrato.map((item) => item.idContrato); // Obtener idCliente de cada objeto
        const idsStringContrato = idArrayContrato.join(",");

        // Guardar datos adicionales en localStorage
        const formattedListContrato = rawListContrato
          .map((item) => `${item.idContrato}-${item.name}`)
          .join(",");
        window.localStorage.setItem("listContrato", idsStringContrato);
        window.localStorage.setItem("listRawContrato", formattedListContrato);

        M.toast({ html: "Acceso Correcto", classes: "green darken-1" });
        navigate("/dashboard");
      } else {
        M.toast({ html: response.data.message, classes: "red darken-1" });
      }
    } catch (err) {
      console.error(err);
      M.toast({
        html: "Usuario o contraseña incorrecta",
        classes: "red darken-1",
      });
    }
  };

  return (
    <Form
      username={username}
      setUsername={setUsername}
      password={password}
      setPassword={setPassword}
      onSubmit={onSubmit}
    />
  );
};

const Form = ({ username, setUsername, password, setPassword, onSubmit }) => {
  return (
    <div className="container">
      <form onSubmit={onSubmit}>
        <div className="form-group">
          <label htmlFor="username">Usuario:</label>
          <input
            type="text"
            id="username"
            value={username}
            onChange={(event) => setUsername(event.target.value)}
            required
            style={{
              padding: "8px",
              margin: "5px 0",
              borderRadius: "4px",
              border: "1px solid #ccc",
              width: "100%",
            }}
          />
        </div>
        <div className="form-group">
          <label htmlFor="password">Password:</label>
          <input
            type="password"
            id="password"
            value={password}
            onChange={(event) => setPassword(event.target.value)}
            required
            style={{
              padding: "8px",
              margin: "5px 0",
              borderRadius: "4px",
              border: "1px solid #ccc",
              width: "100%",
            }}
          />
        </div>

        <StyledFormButton type="submit">Entrar</StyledFormButton>
      </form>
    </div>
  );
};
