import React, { useState, useEffect, useRef } from "react";
import "materialize-css/dist/css/materialize.min.css";
import M from "materialize-css";
import { useGetClientId } from "../../hooks/useGetUserClientId";
import { StyledFormButton } from "../Styles";
import { hasWritePermissions } from "../apiCredentials";
import "materialize-css/dist/js/materialize.min.js";

const AltaEmpleado = ({ selectedIds }) => {
  const canWrite = hasWritePermissions();
  const idsParam = selectedIds.join(",");
  const CLIENTID = useGetClientId();

  const timePickerRefHe = useRef(null);
  const timePickerRefHs = useRef(null);
  const datePickerRefFechaDeInicio = useRef(null);
  const useridetr = window.localStorage.getItem("userID");

  const contracts = {
    1: "5-Corporativo licco",
    7: "1074-FONACOT",
    8: "1075-BIENESTAR",
    9: "1076-INFONATEL VANGUARDIA",
    11: "1080-BANXICO GVIC",
    12: "1081-REPUVE GVIC",
    13: "1082-PRODECON GVIC",
    14: "1083-NAFIN GVIC",
    15: "1084-INFONATEL GTI",
    16: "1085-FULLER GVIC",
    17: "1086-BACK OFFICE",
  };

  const [formData, setFormData] = useState({
    empid: "",
    nombre: "",
    paterno: "",
    materno: "",
    curp: "",
    puesto: "",
    site: "",
    sucursal: "",
    he: "",
    hs: "",
    fecha_entrada: "",
    email: "",
    tipo: "",
  });

  const [curpList, setCurpList] = useState([]);
  const [empidList, setEmpidList] = useState([]);
  const [errors, setErrors] = useState({
    curp: "",
    curpExists: "",
    empid: "",
    empidExists: "",
  });
  const [loading, setLoading] = useState(false);

  // Inicializa los componentes de Materialize
  useEffect(() => {
    M.Timepicker.init(timePickerRefHe.current, {
      twelveHour: false,
      defaultTime: "now",
      autoClose: false,
      onCloseEnd: () => {
        console.log("Timepicker HE se cerró.");
      },
      onSelect: (hour, minute) => {
        console.log("Hora seleccionada:", hour, minute);
        setFormData((prevData) => ({
          ...prevData,
          he: `${String(hour).padStart(2, "0")}:${String(minute).padStart(
            2,
            "0"
          )}`,
        }));
      },
    });

    // Timepicker Salida
    M.Timepicker.init(timePickerRefHs.current, {
      twelveHour: false,
      defaultTime: "now",
      autoClose: true,
      onSelect: (hour, minute) => {
        console.log("Hora seleccionada:", hour, minute);
        setFormData((prevData) => ({
          ...prevData,
          hs: `${String(hour).padStart(2, "0")}:${String(minute).padStart(
            2,
            "0"
          )}`,
        }));
      },
    });

    M.Datepicker.init(datePickerRefFechaDeInicio.current, {
      format: "yyyy-mm-dd",
      autoClose: false,
      onClose: () => {
        console.log("Datepicker Fecha de Inicio se cerró.");
      },
      onSelect: (date) => {
        console.log("Fecha seleccionada:", date);
        const formattedDate = date.toISOString().split("T")[0];
        setFormData((prevData) => ({
          ...prevData,
          fecha_entrada: formattedDate,
        }));
      },
    });
  }, []);

  // Carga listas de curp y empid existentes
  useEffect(() => {
    fetch(
      `https://894bdij9ij.execute-api.us-east-1.amazonaws.com/licco/empleados/${idsParam}-${useridetr}`
    )
      .then((response) => response.json())
      .then((data) => {
        if (Array.isArray(data) && data.length > 0) {
          setCurpList(data.map((emp) => emp.curp));
          setEmpidList(data.map((emp) => emp.empnum));
        }
      })
      .catch((error) => console.error("Error al obtener empleados:", error));
  }, [CLIENTID, idsParam]);

  // Validación de curp y empid en tiempo real
  useEffect(() => {
    const { curp, empid } = formData;
    let newErrors = { curp: "", curpExists: "", empid: "", empidExists: "" };

    if (curpList.includes(curp)) {
      newErrors.curpExists = "CURP ya registrado";
    } else if (curp.length !== 18 || !/^[a-zA-Z0-9]{18}$/.test(curp)) {
      newErrors.curp = "No son 18 dígitos";
    }

    if (empidList.includes(empid)) {
      newErrors.empidExists = "ID de empleado ya registrado";
    }

    setErrors(newErrors);
  }, [formData.curp, formData.empid, curpList, empidList]);

  // Ajusta sucursal con base en CLIENTID
  useEffect(() => {
    if (CLIENTID in contracts) {
      setFormData((prevData) => ({
        ...prevData,
        sucursal: contracts[CLIENTID],
      }));
    }
  }, [CLIENTID]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    // Si el rol es "USER", no permitimos el submit:
    if (!canWrite) {
      M.toast({
        html: "No tienes permisos para dar de alta un empleado (Solo Lectura).",
        classes: "red",
      });
      return;
    }

    // Verifica si hay errores de validación
    if (
      errors.curp ||
      errors.curpExists ||
      errors.empid ||
      errors.empidExists
    ) {
      M.toast({
        html: "Por favor corrige los errores antes de guardar.",
        classes: "red",
        displayLength: 4000,
        inDuration: 300,
        outDuration: 375,
      });
      return;
    }

    // Verificar si se seleccionó un contrato
    if (idsParam.length === 0) {
      M.toast({
        html: "Selecciona un contrato antes de guardar.",
        classes: "red",
        displayLength: 4000,
        inDuration: 300,
        outDuration: 375,
      });
      return;
    }

    const getTodayDate = () => {
      const today = new Date();
      const year = today.getFullYear();
      const month = String(today.getMonth() + 1).padStart(2, "0");
      const day = String(today.getDate()).padStart(2, "0");
      return `${year}-${month}-${day}`;
    };

    const formatTo24Hour = (time) => {
      const [hours, minutes] = time.split(":").map(Number);
      return `${String(hours).padStart(2, "0")}:${String(minutes).padStart(
        2,
        "0"
      )}`;
    };

    const payload = {
      empnum: formData.empid,
      nombre: formData.nombre.toString().toUpperCase(),
      paterno: formData.paterno.toUpperCase(),
      materno: formData.materno.toUpperCase() || " ",
      curp: formData.curp.toUpperCase(),
      sucursal: window.localStorage.getItem("nameContrato") || "5-LICCO",
      frecuenciaDePago: "Quincena",
      fechaDeInicio: formData.fecha_entrada || getTodayDate(),
      fijamovil: "Fija",
      horarioDeEntrada24hh: formatTo24Hour(formData.he || "12:00"),
      horarioDeSalida60mm: formData.hs || "20:00",
      toleranciaARetardoMmssNo: "PPP",
      horasExtraSino: "LLL",
      turnoDominicalSino: "",
      diasDeDescanso16: "2",
      horasSemanales: "48",
      activoInactivo: "Activo",
      fotoEnviada: "Si",
      email: formData.email || "correotest@gmail.com",
      clientid: window.localStorage.getItem("idClienteSeleccionado") || "1",
    };

    console.log(formData);

    setLoading(true);

    fetch(
      "https://894bdij9ij.execute-api.us-east-1.amazonaws.com/licco/empleado",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(payload),
      }
    )
      .then((response) => response.json())
      .then((data) => {
        setLoading(false);
        if (data.error_code === "0") {
          // Alta exitosa
          M.toast({
            html: "Usuario guardado exitosamente!",
            classes: "green",
            displayLength: 4000,
            inDuration: 300,
            outDuration: 375,
          });
          //window.location.reload();
          // Limpiar formulario
          setFormData({
            empid: "",
            nombre: "",
            paterno: "",
            materno: "",
            curp: "",
            puesto: "",
            sucursal: "",
            he: "",
            hs: "",
            frecuencia: "",
            fecha_entrada: "",
            email: "",
            tipo: "",
            site: "",
          });
        } else {
          // Ocurrió un error. Mostrar mensaje y NO limpiar formulario
          M.toast({
            html: data.error_msg || "Ocurrió un error al guardar el usuario.",
            classes: "red",
            displayLength: 4000,
            inDuration: 300,
            outDuration: 375,
          });
          console.error("Error del endpoint:", data.error_msg);
        }
      })
      .catch((error) => {
        setLoading(false);
        M.toast({
          html: "Error de red o servidor al guardar el usuario.",
          classes: "red",
          displayLength: 4000,
          inDuration: 300,
          outDuration: 375,
        });
        console.error("Error al guardar el usuario:", error);
      });
  };

  return (
    <div className="container">
      <div className="section">
        <form onSubmit={handleSubmit}>
          {/* Secciones del formulario */}
          <div className="row" style={{ marginBottom: "10px" }}>
            <div className="input-field col s2">
              <input
                id="empid"
                type="text"
                name="empid"
                className="validate"
                value={formData.empid}
                onChange={handleInputChange}
                required
                minLength="4"
                maxLength="6"
                pattern="[0-9]*"
              />
              <span className="helper-text red-text" style={{ color: "red" }}>
                {errors.empid || errors.empidExists}
              </span>
              <label htmlFor="empid">No. Empleado</label>
            </div>

            <div className="input-field col s4">
              <input
                id="nombre"
                type="text"
                name="nombre"
                className="validate"
                value={formData.nombre}
                onChange={handleInputChange}
                pattern="[a-zA-Z\s]+"
                required
              />
              <label htmlFor="nombre">Nombre</label>
            </div>

            <div className="input-field col s3">
              <input
                id="paterno"
                type="text"
                name="paterno"
                className="validate"
                value={formData.paterno}
                onChange={handleInputChange}
                pattern="[a-zA-Z\s]+"
                required
              />
              <label htmlFor="paterno">Paterno</label>
            </div>

            <div className="input-field col s3">
              <input
                id="materno"
                type="text"
                name="materno"
                className="validate"
                value={formData.materno}
                onChange={handleInputChange}
                pattern="[a-zA-Z\s]+"
              />
              <label htmlFor="materno">Materno</label>
            </div>
          </div>

          <div className="row">
            <div className="input-field col s4">
              <input
                id="curp"
                type="text"
                name="curp"
                className={`validate ${
                  errors.curp || errors.curpExists ? "invalid" : ""
                }`}
                value={formData.curp}
                onChange={handleInputChange}
                required
                minLength="18"
                maxLength="18"
                pattern="[a-zA-Z0-9]{18}"
              />
              <label htmlFor="curp">CURP</label>
              <span className="helper-text red-text" style={{ color: "red" }}>
                {errors.curp || errors.curpExists}
              </span>
            </div>

            <div className="input-field col s4">
              <input
                id="puesto"
                type="text"
                name="puesto"
                className="validate"
                required
                value={formData.puesto}
                onChange={handleInputChange}
              />
              <label htmlFor="puesto">Puesto</label>
            </div>

            <div className="input-field col s4">
              <input
                id="site"
                type="text"
                name="site"
                className="validate"
                required
                value={formData.site}
                onChange={handleInputChange}
              />
              <label htmlFor="site">SITE</label>
            </div>
          </div>

          <div className="row">
            <div className="input-field col s4">
              <input
                id="email"
                type="email"
                name="email"
                className="validate"
                value={formData.email}
                onChange={handleInputChange}
              />
              <label htmlFor="email">Email</label>
            </div>

            <div className="input-field col s3">
              <input
                id="he"
                type="text"
                name="he"
                className="validate timepicker"
                value={formData.he}
                required
                ref={timePickerRefHe}
                //onChange={handleInputChange}
                readOnly
              />
              <label htmlFor="he">Horario Entrada</label>
            </div>

            <div className="input-field col s3">
              <input
                id="hs"
                type="text"
                name="hs"
                className="validate timepicker"
                value={formData.hs}
                required
                ref={timePickerRefHs}
                //onChange={handleInputChange}
                readOnly
              />
              <label htmlFor="hs">Horario Salida</label>
            </div>

            <div className="input-field col s3">
              <input
                id="fechaDeInicio"
                type="text"
                name="fecha_entrada"
                className="validate datepicker"
                value={formData.fecha_entrada}
                ref={datePickerRefFechaDeInicio}
                //onChange={handleInputChange}
                readOnly
              />
              <label htmlFor="fecha_entrada">Fecha de Inicio</label>
            </div>
          </div>

          {/* BOTÓN para crear nuevo empleado */}
          <div className="row">
            <div className="input-field col s12">
              <StyledFormButton
                type="submit"
                // Deshabilitamos si: loading o no canWrite
                disabled={loading || !canWrite}
              >
                {loading
                  ? "Loading..."
                  : canWrite
                  ? "Nuevo Empleado"
                  : "Solo Lectura"}
              </StyledFormButton>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default AltaEmpleado;
