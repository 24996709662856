import React, { useState, useEffect, useRef } from "react";
import "tabulator-tables/dist/css/tabulator.min.css";
import "flatpickr/dist/flatpickr.min.css";
import Flatpickr from "flatpickr";
import { Spanish } from "flatpickr/dist/l10n/es"; // Import Spanish locale
import { TabulatorFull as Tabulator } from "tabulator-tables";
import { hasWritePermissions } from "../apiCredentials";
import * as XLSX from "xlsx";
import { StyledFormButton, StyledExcelButton } from "../Styles";
import "flatpickr/dist/flatpickr.min.css"; // Default Flatpickr styles
import "../custom-flatpickr.css";

const CalendarioFLava = () => {
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [table, setTable] = useState(null);
  const [loading, setLoading] = useState(false);

  const startDateRef = useRef(null);
  const endDateRef = useRef(null);
  const CLIENTID = window.localStorage.getItem("idClienteSeleccionado");
  const useridetr = window.localStorage.getItem("userID");
  //console.log(useridetr);
  const userTipe = hasWritePermissions();

  useEffect(() => {
    const today = new Date();
    const fifteenDaysAgo = new Date();
    fifteenDaysAgo.setDate(today.getDate() - 7);

    const formattedToday = today.toISOString().split("T")[0];
    const formattedFifteenDaysAgo = fifteenDaysAgo.toISOString().split("T")[0];

    setStartDate(formattedFifteenDaysAgo);
    setEndDate(formattedToday);
  }, []);

  // Initialize Flatpickr with Spanish locale and Materialize CSS styles
  useEffect(() => {
    Flatpickr(startDateRef.current, {
      dateFormat: "Y-m-d",
      locale: Spanish, // Use Spanish locale
      defaultDate: startDate,
      onChange: ([date]) => setStartDate(date.toISOString().split("T")[0]),
    });
    Flatpickr(endDateRef.current, {
      dateFormat: "Y-m-d",
      locale: Spanish, // Use Spanish locale
      defaultDate: endDate,
      onChange: ([date]) => setEndDate(date.toISOString().split("T")[0]),
    });
  }, [startDate, endDate]);

  // Redraw the table whenever the table instance changes
  useEffect(() => {
    if (table) {
      table.redraw(true);
    }
  }, [table]);

  // Fetch data and populate table
  const handleFetchData = async () => {
    setLoading(true);
    const { transformedData, columns } = await fetchData(startDate, endDate);
    if (table) {
      table.setData(transformedData);
      table.setColumns(columns);
    } else {
      const newTable = new Tabulator("#data-table", {
        data: transformedData,
        layout: "fitDataStretch",
        height: "550px",
        columns: columns,
        groupBy: "Site",
        groupHeader: function (value, count) {
          // Only show the count, without the label
          return `${value}: ${count}`;
        },
        pagination: "remote",
        paginationSize:30
      });
      setTable(newTable);
    }
    setLoading(false);
  };

  // Download data as Excel
  const handleDownload = async () => {
    setLoading(true);
    const { transformedData, columns } = await fetchData(startDate, endDate);
    const downloadableData = transformedData.map((row) => {
      const newRow = {};
      columns.forEach((col) => {
        newRow[col.title] = row[col.field];
      });
      return newRow;
    });

    const worksheet = XLSX.utils.json_to_sheet(downloadableData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");

    const dataUri = XLSX.write(workbook, { bookType: "xlsx", type: "base64" });
    const link = document.createElement("a");
    const downloadName = `${
      transformedData[0]?.contract_name || "data"
    }_${startDate}-${endDate}`;
    link.href = `data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,${dataUri}`;
    link.download = `${downloadName}.xlsx`;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    setLoading(false);
  };

  // Fetch data from the API and transform it for the table
  const fetchData = async (startDate, endDate) => {
    const baseUrl =
      "https://894bdij9ij.execute-api.us-east-1.amazonaws.com/licco/asistencias/prenom/";

      const url = `${baseUrl}${startDate}/${endDate}/${CLIENTID}-${useridetr}`;
    

    try {
      const response = await fetch(url);
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const data = await response.json();
      return transformData(data);
    } catch (error) {
      console.error("Error fetching data:", error);
      return { transformedData: [], columns: [] };
    }
  };

  // Transform data and add columns for counts of specific "hora" and "retardo"
  const transformData = (data) => {
    const pivotData = {};
    const dates = new Set();
    let maxFI = 0;

    // Variables para almacenar los totales
    let totalFI = 0, totalINC = 0, totalV = 0, totalD = 0, totalTurno = 0;


    // Initialize counters for specific 'hora' values and retardo

    data.forEach((item) => {
      if (!pivotData[item.nombre]) {
        pivotData[item.nombre] = {
          nombre: toUpperCaseName(item.nombre),
          contract_name: item.contract_name,
          Site: item.e_attribute4,
          "ID Nominas": item.employee_number,
          PUESTO: item.e_attribute3,
          fechadeinicio: item.fechadeinicio,
          fechabaja: item.fechabaja,
          horario: item.horas,
          retardoCount: 0,
          FI: 0,
          V: 0,
          INC: 0,
          D:0,
          turnoSum:0,
        };
      }


        // Mapeo de valores de "hora" a las propiedades correspondientes de pivotData
        const horaMapping = { 
          "D": "D", 
          "V": "V", 
          "INC": "INC", 
        };

        // Cambiar "hora" si es "Sab" o "Dom"
        if (item.hora === "Sab" || item.hora === "Dom") {
          item.hora = "F";
        }
        if (item.hora === "FI" || item.hora === "F"|| item.hora === "FJ") pivotData[item.nombre].FI++;

      

        // Actualizar el valor correspondiente en pivotData usando el mapeo
        if (horaMapping[item.hora]) {
          pivotData[item.nombre][horaMapping[item.hora]]++;
        }

        const horas =item.hora; // Obtener las horas del día
          const turno = calculateTurno(horas); // Calcular los turnos

          // Sumar los turnos según la cantidad de horas
          if (turno > 6.5 && turno < 11.5) {
            pivotData[item.nombre].turnoSum += 1;
          } else if (turno > 11.5 && turno < 17 ) {
          pivotData[item.nombre].turnoSum += 2;
          }else if (turno > 17 ) {
          pivotData[item.nombre].turnoSum += 3;
          }else {
          pivotData[item.nombre].turnoSum += 0; // Opcional, ya que sumar 0 no cambia el valor
         }


        
          if (horas === "AJ") {
            pivotData[item.nombre].turnoSum += 1; // Agregar un turno por "AJ"
        } else if (horas === "DAA"){
          pivotData[item.nombre].turnoSum += 2;
        }

        // Contar el retardo si corresponde
        if (item.retardo === "Y") {
          pivotData[item.nombre].retardoCount++;
        }

        // Registrar la fecha y el retardo en pivotData
        pivotData[item.nombre][item.fecha] = item.hora;
        pivotData[item.nombre][`${item.fecha}_retardo`] = item.retardo;

        // Añadir la fecha al conjunto de fechas
        dates.add(item.fecha);


     
    });

    

    Object.values(pivotData).forEach((item) => {
      if (item.FI > maxFI) {
        maxFI = item.FI;
      }
    });

    const transformedData = Object.values(pivotData);

    // Calcular totales
    transformedData.forEach((item) => {
      totalFI += item.FI;
      totalINC += item.INC;
      totalV += item.V;
      totalD += item.D;
      totalTurno += item.turnoSum;
  });


    // Crear una fila vacía con las mismas claves que un empleado real
const emptyRow = Object.fromEntries(
  Object.keys(transformedData[0] || {}).map((key) => [key, ""])
);



// Agregar el título "TURNOS" en la columna 'nombre'

// Variable para almacenar la suma total de los turnos de todos los días



    // Sumar los turnos por día y actualizar la fila "TURNOS"
    dates.forEach((date) => {
      let totalTurnos = 0;

      // Iterar sobre todos los empleados para sumar los turnos
      transformedData.forEach((item) => {
          const horas = item[date]; // Obtener las horas del día
          const turno = calculateTurno(horas); // Calcular los turnos

          // Sumar los turnos según la cantidad de horas
          if (turno > 6.5 && turno < 11.5) {
            totalTurnos += 1;
        } else if (turno > 11.5 && turno < 17 ) {
          totalTurnos += 2;
        }else if (turno > 17 ) {
          totalTurnos += 3;
        }else {
          totalTurnos += 0; // Opcional, ya que sumar 0 no cambia el valor
        }

          if (horas === "AJ") {
            totalTurnos += 1; // Agregar un turno por "AJ"
        } else if (horas === "DAA"){
          totalTurnos += 2;
        }
      });

      emptyRow[date] = totalTurnos; // Asignar la suma de los turnos en la fila "TURNOS" para esa fecha

  });

          // Agregar las sumas totales a la fila "emptyRow"
          emptyRow.Site = "TOTALES";
          emptyRow.nombre = "TOTALES";
          emptyRow.FI = totalFI;
          emptyRow.INC = totalINC;
          emptyRow.V = totalV;
          emptyRow.D = totalD;
          emptyRow.turnoSum = totalTurno;




         
// Agregar la fila vacía al final de los datos transformados
transformedData.unshift(emptyRow);


    const columns = [
      { 
        title: "#", 
        field: "rownum", 
        formatter: function(cell) {
          return cell.getRow().getPosition() - 1; // Restamos 1 para comenzar desde 0
        }, 
        width: 50, 
        frozen: true, 
        editable: false 
      },
      {
        title: "NUMERO",
        field: "ID Nominas",
        frozen: true,
        width: 90,
        headerFilter: "input",
        headerFilterPlaceholder: "Buscar...",
      },

      {
        title: "NOMBRE",
        field: "nombre",
        headerFilter: "input",
        frozen: true,
        headerFilterFunc: (headerValue, rowValue) =>
          rowValue.toLowerCase().includes(headerValue.toLowerCase()),
        formatter: function (cell) {
          const value = cell.getValue();
          if (value === "TOTALES") {
            cell.getElement().style.fontWeight = "bold";
            cell.getElement().style.backgroundColor = "#f0f0f0"; // Color de fondo distintivo
          }
          return value;
        },
        
      },
      //{ title: "PUESTO", field: "PUESTO", width: 99 },
      { title: "CONTRATO", field: "contract_name", width: 99 },
      //{ title: "INGRESO", field: "fechadeinicio", width: 99 },
      //{ title: "BAJA", field: "fechabaja", width: 77 },
      //{ title: "HORARIO", field: "horario", width: 99 },
      {
        title: "FALTA",
        field: "FI",
        width: 90,
        hozAlign: "center",
        // formatter: function (cell) {
        //   const value = cell.getValue();
        //   const cellElement = cell.getElement();

        //   // Determine the background color based on the value
        //   let backgroundColor;

        //   if (value === 0) {
        //     backgroundColor = "white"; // For value 0
        //   } else if (value === 1 || value === 2) {
        //     backgroundColor = "pink"; // For values 1 and 2
        //   } else {
        //     backgroundColor = "red"; // For values 3 or more
        //   }

        //   // Set the background color based on the value
        //   cellElement.style.backgroundColor = backgroundColor;
        //   cellElement.style.color = value > 0 ? "white" : "black"; // Optional: Adjust text color for better contrast

        //   return value;
        // },
      },
      { title: "INC", field: "INC", width: 70, hozAlign: "center" },
      { title: "VAC", field: "V", width: 70, hozAlign: "center" },
      { title: "DES", field: "D", width: 70, hozAlign: "center" },
      { title: "TURNO", field: "turnoSum", width: 90, hozAlign: "center" },
    ];

    dates.forEach((date) => {
      columns.push({
        title: date,
        field: date,
        headerVertical: true,
        hozAlign: "center",
        formatter: function (cell) {
          const value = cell.getValue(); // Get the value of the cell
          const rowData = cell.getRow().getData(); // Get the row data
          if(value === 'Sab' || value === 'Dom'){
            return 'F';
          }

          // Check if the retardo for this date and row is "Y"
          const isRetardo = rowData[`${date}_retardo`] === "Y";

          // Debugging: Log the row data and condition
          //console.log(`Date: ${date}, Retardo: ${rowData[`${date}_retardo`]}`);

          // Set the text color based on the retardo value
          cell.getElement().style.color = isRetardo ? "black" : "black";

          return value; // Return the value to be displayed in the cell
        },
      } 
    
    );
    })
    
    ;
    //console.log(transformedData);
    return { transformedData, columns };
  };

  const toUpperCaseName = (name) =>{
    return name.toUpperCase();
  };
  

  const calculateTurno = (horas) => {
    if (!horas || typeof horas !== "string") return "";

    const horasArray = horas.split(",");
    if (horasArray.length < 2) return ""; // Si no hay al menos dos valores, no se puede calcular

    const start = horasArray[0]; // Primer valor (hora de entrada)
    const end = horasArray[horasArray.length - 1]; // Último valor (hora de salida)

    //console.log("Entrada:", start, "Salida:", end);

    const [startHour, startMinute] = start.split(":").map(Number);
    const [endHour, endMinute] = end.split(":").map(Number);

    if (isNaN(startHour) || isNaN(startMinute) || isNaN(endHour) || isNaN(endMinute)) {
      //  console.error("Error en los datos de hora:", horas);
        return "";
    }

    const startTime = new Date(0, 0, 0, startHour, startMinute, 0);
    const endTime = new Date(0, 0, 0, endHour, endMinute, 0);

    let diff = (endTime - startTime) / (1000 * 60 * 60); // Diferencia en horas

    if (diff < 0) {
        diff += 24; // Ajustar si el turno pasa de medianoche
    }

    return diff.toFixed(2);
  };


  return (
    <div>
      <div className="row">
        <div className="col s3">
          <label>Fecha de inicio</label>
          <input ref={startDateRef} type="text" />
        </div>
        <div className="col s3">
          <label>Fecha de fin</label>
          <input ref={endDateRef} type="text" />
        </div>
      </div>

      <div className="row">
        <div className="col s6">
          <StyledFormButton onClick={handleFetchData}>Buscar</StyledFormButton>
        </div>
        <div className="col s3 right">
          <StyledExcelButton onClick={handleDownload}>
            Descargar Excel
          </StyledExcelButton>
        </div>
      </div>
      {loading && (
        <div className="progress">
          <div className="indeterminate"></div>
        </div>
      )}

      <div id="data-table" />
    </div>
  );
};

export default CalendarioFLava;
