import React, { useState, useEffect, useRef } from "react";
import "tabulator-tables/dist/css/tabulator.min.css";
import "flatpickr/dist/flatpickr.min.css";
import Flatpickr from "flatpickr";
import { TabulatorFull as Tabulator } from "tabulator-tables";
import * as XLSX from "xlsx";

//import CharacterCount from './CuntPrenomina';

import { StyledExcelButton, StyledFormButton, ButtonGroup } from "../Styles";

const PrenominaLavaTap = () => {
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [table, setTable] = useState(null);
  const [loading, setLoading] = useState(false);

  const startDateRef = useRef(null);
  const endDateRef = useRef(null);
  const CLIENTID = window.localStorage.getItem("idClienteSeleccionado");
  const useridetr = window.localStorage.getItem("userID");

  useEffect(() => {
    Flatpickr(startDateRef.current, {
      dateFormat: "Y-m-d",
      onChange: ([date]) => setStartDate(date.toISOString().split("T")[0]),
    });
    Flatpickr(endDateRef.current, {
      dateFormat: "Y-m-d",
      onChange: ([date]) => setEndDate(date.toISOString().split("T")[0]),
    });
  }, []);

  useEffect(() => {
    if (table) {
      table.redraw(true); // Redraw the table to ensure layout is updated
    }
  }, [table]);

  const handleFetchData = async () => {
    setLoading(true);
    const { transformedData, columns } = await fetchData(startDate, endDate);
    if (table) {
      table.setData(transformedData);
      table.setColumns(columns);
    } else {
      const newTable = new Tabulator("#data-table", {
        data: transformedData,
        layout: "fitDataStretch",
        columns: columns,
        renderHorizontal: "virtual",
        height: "500px", // Fija la altura de la tabla para activar el scroll interno
        resizableColumnFit: false,
        pagination: "local", // Enable local pagination
        paginationSize: 80, // Number of rows per page
        cellVertAlign: "middle",
        cellHozAlign: "center",
      });
      setTable(newTable);
    }
    setLoading(false);
  };

  const handleDownload = async () => {
    setLoading(true);
    const { transformedData, columns } = await fetchData(startDate, endDate, true);
    const downloadableData = transformedData.map((row) => {
      const newRow = {};
      columns.forEach((col) => {
        newRow[col.title] = row[col.field];
      });
      return newRow;
    });

    const worksheet = XLSX.utils.json_to_sheet(downloadableData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");

    const dataUri = XLSX.write(workbook, { bookType: "xlsx", type: "base64" });
    const link = document.createElement("a");
    const downloadName = `${
      transformedData[0]?.contract_name || "data"
    }_${startDate}-${endDate}`;
    link.href = `data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,${dataUri}`;
    link.download = `${downloadName}.xlsx`;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    setLoading(false);
  };

  const fetchData = async (startDate, endDate, isDownload = false) => {
    const baseUrl =
      "https://894bdij9ij.execute-api.us-east-1.amazonaws.com/licco/asistencias/prenom/";
    //const url = `${baseUrl}${startDate}/${endDate}/${CLIENTID}`;
    const url = `${baseUrl}${startDate}/${endDate}/${CLIENTID}-${useridetr}`; //

    try {
      const response = await fetch(url);
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const data = await response.json();
      return isDownload ? transformDataExcel(data) : transformData(data);
    } catch (error) {
      console.error("Error fetching data:", error);
      return { transformedData: [], columns: [] };
    }
  };

 
  const transformData = (data) => {
    const pivotData = {};
    const dates = new Set();
    let countMap = {};

    data.forEach((item) => {
      if (!pivotData[item.nombre]) {
        pivotData[item.nombre] = {
          nombre: item.nombre,
          //super: item.e_attribute2,
          contract_name: item.contract_name,
          Site: item.e_attribute4,
          "ID Nominas": item.employee_number,
          descripcion: item.e_attribute3,
          //TURNO: determineTurno(item.horas),
          fechadeinicio: item.fechadeinicio || "",
          fechabaja: item.fechabaja || "-",
          horario: item.horas || "",
          pagoextra_dias: "",
          pridom_dias: "",
          clave:claveContrato(CLIENTID),
          numSuper:obtenerNumero(item.e_attribute2),
          nameSuper:obtenerNombre(item.e_attribute2),
         
        };
      }
          let horasValue = item.hora;

          // Definir conjuntos para comparaciones eficientes
          const valoresF = new Set(["FI", "Dom", "Sab"]);
          const valores1 = new Set(["PC/G", "A", "DAA"]);
          const valoresCount = new Set(["FI", "B", "INC", "AA", "SUS", "PS/G", "FJ", "AUS", "F", "V"]);
          
          const esRetardo = /[\d]/.test(horasValue) || item.retardo === "Y";
          
          // Determinar el valor de horasValue de manera clara y eficiente
          if (valoresF.has(horasValue)) {
              horasValue = "F";
          } else if (valores1.has(horasValue) || esRetardo) {
              horasValue = "1";
          } else if (!horasValue) { // Equivalente a horasValue === ""
              horasValue = "+1";
          }
          
          // Asignar valor a pivotData y registrar fecha
          pivotData[item.nombre][item.fecha] = horasValue;
          dates.add(item.fecha);
          
          // Contabilizar ciertos valores en countMap
          if (valoresCount.has(horasValue)) {
              countMap[item.nombre] = (countMap[item.nombre] || 0) + 1;
          }
          
          // Calcular jornada
          const jornada = calculateTurno(item.hora);
          
          // Contabilizar pago extra si jornada > 12
          if (jornada > 12 || horasValue === "DAA") {
              pivotData[item.nombre].pagoextra_dias++;
          }
          
          // Verificar si es domingo y cumple condiciones para pridom_dias
          const fecha = new Date(item.fecha);
          if ((esDomingo(fecha) && (jornada > 5 || horasValue === "+1")) || ((esDomingo(fecha) && horasValue === "AJ") || (esDomingo(fecha) && horasValue === "DAA"))) {
              pivotData[item.nombre].pridom_dias++;
          }
    });

    

    const filteredData = Object.values(pivotData).filter((item) => {
      const horasValuesInRange = Array.from(dates)
        .map((date) => item[date])
        .filter(Boolean);
      return !horasValuesInRange.every((value) => value === "B");
    });

    const transformedData = filteredData.map((item, index) => {
      const totalDays = 15; // Assuming 15 is the total number of days for calculation

      // Calculate the Last_Column value
      const count = countMap[item.nombre] || 0;
      const lastColumnValue = totalDays - count;

      return {
        consecutiveNumber: index + 1,
        ...item,
        Last_Column: Math.max(0, lastColumnValue), // Ensure the value is not less than zero
      };
    });

    const columns = [
        {
            title: "num",
            field: "ID Nominas",
            frozen: true,
            width: 90,
            headerFilter: "input",
            headerFilterParams: {
                placeholder: "Search...",
                minSearchChars: 2,
            },
            headerFilterPlaceholder: "Buscar...",
            formatter: function (cell) {
              let value = cell.getValue();
              let element = cell.getElement();
              element.style.fontWeight = "bold";
              return value;
          }
        },
        {
            title: "nombre",
            field: "nombre",
            frozen: true,
            headerFilter: "input",
            headerFilterParams: {
                minSearchChars: 2,
            },
            headerFilterFunc: (headerValue, rowValue) =>
                rowValue.toUpperCase().includes(headerValue.toUpperCase()),
            formatter: function (cell) {
              let value = cell.getValue();
              let element = cell.getElement();
              element.style.fontWeight = "bold";
              return value;
          }
        },
        { title: "clave", field: "clave", width: 90,
          formatter: function (cell) {
            let value = cell.getValue();
            let element = cell.getElement();
            element.style.fontWeight = "bold";
            return value;
        }
         },
        { title: "cambio_clave", field: "", width: 80, hozAlign: "center" },
        { title: "cerrar", field: "", width: 80, hozAlign: "center" },
        { title: "vacambio_superc", field: "", width: 80, hozAlign: "center" },
        { title: "motivo_0_dias", field: "", width: 80, hozAlign: "center" },
        { title: "varios", field: "", width: 80, hozAlign: "center" },
    ];

    
    columns.push(
        {
            title: "diastra",
            field: "Last_Column",
            hozAlign: "center",
            formatter: function (cell) {
              let value = cell.getValue();
              let element = cell.getElement();
              element.style.fontWeight = "bold";
              return value;
          }
        },
    );

    dates.forEach((date) => {columns.push({title: date,field: date,headerVertical: true,align: "center",
      formatter: function (cell) {
      let value = cell.getValue();
      let element = cell.getElement();
      element.style.fontWeight = "bold";
      if (value === "+1") {
         
          cell.getElement().style.color = "green";
      }else if (value === "F") {
         
        cell.getElement().style.color = "red";
    }
      return value;
  }
}
    );});

    columns.push(
      { title: "pridom_dias", field: "pridom_dias", width: 120, hozAlign: "center" , formatter: function (cell) {
        let value = cell.getValue();
        let element = cell.getElement();
        element.style.fontWeight = "bold";
        return value;
    }},
      { title: "pagoextra_dias", field: "pagoextra_dias", width: 120, hozAlign: "center",
        formatter: function (cell) {
          let value = cell.getValue();
          let element = cell.getElement();
          element.style.fontWeight = "bold";
          return value;
      }
       },
      { title: "pagoextra_importe", field: "", width: 80, hozAlign: "center" },
      { title: "sueldo", field: "", width: 80, hozAlign: "center" },
      { title: "puntualidad", field: "", width: 80, hozAlign: "center" },
      { title: "despensa", field: "", width: 80, hozAlign: "center" },
      { title: "clave_tur", field: "", width: 80, hozAlign: "center" },
      { title: "horasextra_dias", field: " ", width: 80, hozAlign: "center" },
      { title: "horasextra_importe", field: "", width: 80, hozAlign: "center" },
      { title: "ase", field: "", width: 80, hozAlign: "center" },
      { title: "cabo", field: "", width: 80, hozAlign: "center" },
      { title: "super", field: "", width: 80, hozAlign: "center" },
      { title: "contrato", field: "", width: 80, hozAlign: "center" },
      { title: "num_sup", field: "numSuper", width: 80, hozAlign: "center" },
      { title: "nombre_sup", field: "nameSuper", width: 80, hozAlign: "center" },
      { title: "memos", field: "", width: 80, hozAlign: "center" },
  );


    return { transformedData, columns };


      };

      const transformDataExcel = (data) => {
        const pivotData = {};
        const dates = new Set();
        let countMap = {};
    
        data.forEach((item) => {
          if (!pivotData[item.nombre]) {
            pivotData[item.nombre] = {
              nombre: item.nombre,
              //super: item.e_attribute2,
              contract_name: item.contract_name,
              //Site: item.e_attribute4,
              "ID Nominas": item.employee_number,
              descripcion: item.e_attribute3,
              //TURNO: determineTurno(item.horas),
              //fechadeinicio: item.fechadeinicio || "",
              //fechabaja: item.fechabaja || "-",
              //horario: item.horas || "",
              pagoextra_dias: "",
              pridom_dias: "",
              clave:claveContrato(CLIENTID),
              numSuper:obtenerNumero(item.e_attribute2),
              nameSuper:obtenerNombre(item.e_attribute2),
             
            };
          }
    
            let horasValue = item.hora;

            // Definir conjuntos para mapeo de valores
            const valoresF = new Set(["FI", "Dom", "Sab"]);
            const valores1 = new Set(["PC/G", "A", "DAA", "", "AJ", "D"]);
            const valoresCount = new Set(["FI", "B", "INC", "AA", "SUS", "PS/G", "FJ", "AUS", "F", "V"]);

            if (valoresF.has(horasValue)) {
                horasValue = "F";
            } else if (valores1.has(horasValue) || /[\d]/.test(horasValue) || item.retardo === "Y") {
                horasValue = "1";
            }

            // Asignar valor al pivotData y registrar la fecha
            pivotData[item.nombre][item.fecha] = horasValue;
            dates.add(item.fecha);

            // Contabilizar ciertos valores en countMap
            if (valoresCount.has(horasValue)) {
                countMap[item.nombre] = (countMap[item.nombre] || 0) + 1;
            }

            // Calcular jornada
            const jornada = calculateTurno(item.hora);

            // Contabilizar pago extra si jornada > 12
            if (jornada > 12 || horasValue === "DAA") {
                pivotData[item.nombre].pagoextra_dias++;
            }

            // Verificar si es domingo y cumple condiciones para pridom_dias
            const fecha = new Date(item.fecha);
            if ((esDomingo(fecha) && (jornada > 5 || horasValue === "1")) || ((esDomingo(fecha) && horasValue === "AJ") || (esDomingo(fecha) && horasValue === "DAA"))) {
              pivotData[item.nombre].pridom_dias++;
          }
    
        });
    
        
    
        const filteredData = Object.values(pivotData).filter((item) => {
          const horasValuesInRange = Array.from(dates)
            .map((date) => item[date])
            .filter(Boolean);
          return !horasValuesInRange.every((value) => value === "B");
        });
    
        const transformedData = filteredData.map((item, index) => {
          const totalDays = 15; // Assuming 15 is the total number of days for calculation
    
          // Calculate the Last_Column value
          const count = countMap[item.nombre] || 0;
          const lastColumnValue = totalDays - count;
    
          return {
            consecutiveNumber: index + 1,
            ...item,
            Last_Column: Math.max(0, lastColumnValue), // Ensure the value is not less than zero
          };
        });
    
        const columns = [
            {
                title: "num",
                field: "ID Nominas",
                frozen: true,
                width: 90,
                headerFilter: "input",
                headerFilterParams: {
                    placeholder: "Search...",
                    minSearchChars: 2,
                },
                headerFilterPlaceholder: "Buscar...",
            },
            {
                title: "nombre",
                field: "nombre",
                frozen: true,
                headerFilter: "input",
                headerFilterParams: {
                    minSearchChars: 2,
                },
                headerFilterFunc: (headerValue, rowValue) =>
                    rowValue.toUpperCase().includes(headerValue.toUpperCase()),
            },
            { title: "clave", field: "clave", width: 90 },
            { title: "cambio_clave", field: "", width: 80, hozAlign: "center" },
            { title: "cerrar", field: "", width: 80, hozAlign: "center" },
            { title: "vacambio_superc", field: "", width: 80, hozAlign: "center" },
            { title: "motivo_0_dias", field: "", width: 80, hozAlign: "center" },
            { title: "varios", field: "", width: 80, hozAlign: "center" },
        ];
    
        
        columns.push(
            {
                title: "diastra",
                field: "Last_Column",
                hozAlign: "center",
            },
        );
    
        dates.forEach((date) => {columns.push({title: date,field: date,headerVertical: true,align: "center",
          formatter: function (cell) {
          let value = cell.getValue();
          if (value === "+1") {
             
              cell.getElement().style.color = "red";
          }
          return value;
      }
    }
        );});
    
        columns.push(
          { title: "pridom_dias", field: "pridom_dias", width: 120, hozAlign: "center" },
          { title: "pagoextra_dias", field: "pagoextra_dias", width: 120, hozAlign: "center" },
          { title: "pagoextra_importe", field: "", width: 80, hozAlign: "center" },
          { title: "sueldo", field: "", width: 80, hozAlign: "center" },
          { title: "puntualidad", field: "", width: 80, hozAlign: "center" },
          { title: "despensa", field: "", width: 80, hozAlign: "center" },
          { title: "clave_tur", field: "", width: 80, hozAlign: "center" },
          { title: "horasextra_dias", field: " ", width: 80, hozAlign: "center" },
          { title: "horasextra_importe", field: "", width: 80, hozAlign: "center" },
          { title: "ase", field: "", width: 80, hozAlign: "center" },
          { title: "cabo", field: "", width: 80, hozAlign: "center" },
          { title: "super", field: "", width: 80, hozAlign: "center" },
          { title: "contrato", field: "", width: 80, hozAlign: "center" },
          { title: "num_sup", field: "numSuper", width: 80, hozAlign: "center" },
          { title: "nombre_sup", field: "nameSuper", width: 80, hozAlign: "center" },
          { title: "memos", field: "", width: 80, hozAlign: "center" },
      );
    
    
        return { transformedData, columns };
          };



  const calculateTurno = (horas) => {
    if (!horas || typeof horas !== "string") return "";

    const horasArray = horas.split(",");
    if (horasArray.length < 2) return ""; // Si no hay al menos dos valores, no se puede calcular

    const start = horasArray[0]; // Primer valor (hora de entrada)
    const end = horasArray[horasArray.length - 1]; // Último valor (hora de salida)

    //console.log("Entrada:", start, "Salida:", end);

    const [startHour, startMinute] = start.split(":").map(Number);
    const [endHour, endMinute] = end.split(":").map(Number);

    if (isNaN(startHour) || isNaN(startMinute) || isNaN(endHour) || isNaN(endMinute)) {
      //  console.error("Error en los datos de hora:", horas);
        return "";
    }

    const startTime = new Date(0, 0, 0, startHour, startMinute, 0);
    const endTime = new Date(0, 0, 0, endHour, endMinute, 0);

    let diff = (endTime - startTime) / (1000 * 60 * 60); // Diferencia en horas

    if (diff < 0) {
        diff += 24; // Ajustar si el turno pasa de medianoche
    }

    return diff.toFixed(2);
  };

  const esDomingo = (fecha) => fecha.getDay() === 6;

  const claveContrato = (id) => (id === "22" ? "CCC002" : "");

  const obtenerNumero = (cadena) => cadena.split("-")[0];
  const obtenerNombre = (cadena) => cadena.split("-")[1];


  const determineTurno = (horas) => {
    if (!horas || typeof horas !== "string") return "Desconocido";

    // Split the `horas` field to get the first part
    const [startTime] = horas.split("-"); // Get the start time part

    // Extract hours and minutes from the start time
    const [hour, minute] = startTime.split(":").map(Number);

    // Check if the hour is less than 13
    if (hour < 13) {
      return "Matutino";
    } else {
      return "Vespertino"; // Return 'Desconocido' if not 'Matutino'
    }
  };

  const toupercaseName = (name) => {
    let touper = name.toUpperCase();
    return touper;
  }

  return (
    <div>
      <div className="row">
        <div className="col s3">
          <label htmlFor="start-date">Fecha de Inicio:</label>
          <input type="text" id="start-date" ref={startDateRef} />
        </div>
        <div className="col s3">
          <label htmlFor="end-date">Fecha Final</label>
          <input type="text" id="end-date" ref={endDateRef} />
        </div>
        <ButtonGroup>
          <StyledFormButton onClick={handleFetchData} disabled={loading}>
            Generar
          </StyledFormButton>
          <StyledExcelButton onClick={handleDownload} disabled={loading}>
            Descargar Excel
          </StyledExcelButton>
        </ButtonGroup>
      </div>
      {loading && (
        <div className="progress">
          <div className="indeterminate"></div>
        </div>
      )}
      <div id="table-container">
        <div id="data-table" className="tabulator"></div>
      </div>
      <div
        id="table-placeholder"
        style={{ display: table ? "none" : "block" }}
      ></div>
    </div>
  );
};

export default PrenominaLavaTap;
