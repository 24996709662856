import React, { useCallback, useState, useEffect } from "react";
import Navbar from "../components/NavbarUsers";
import { StyledSubtitle, colors } from "../components/Styles";
import FaltasTabla from "../components/incidencias/TablaFaltas";
import FaltasTablaLavatap from "../components/incidencias/TablaFaltaLavatap";
import IncidentForm from "../components/incidencias/incidenciasFecha";
import GuardiasFindeSemana from "../components/incidencias/GuardiasFindeSemana";
import SelectIdsComponent from "../components/dashboard/SelectIdsComponent";
import IncidentFormS from "../components/incidencias/incidenciasFechaSupervisores";
import { consultNameEnterprise } from "../components/apiCredentials";
import IncidentFormlavatap from "../components/incidencias/incidenciasFechaLavatap";


const IncidenciasPage = () => {
  const [selectedIds, setSelectedIds] = useState([]);

  const [usuario, setUsuario] = useState(null);

  useEffect(() => {
    const usuarioLogueado = consultNameEnterprise(); // O contexto de autenticación
    console.log(usuarioLogueado);

    setUsuario(usuarioLogueado);
  }, []);
  
  const handleIdsSelection = useCallback((ids) => {
    setSelectedIds(ids);
   
  }, []);

  return (
    <div>
      <Navbar />
      <div className="container">
        <div className="row">
          <SelectIdsComponent onSelect={handleIdsSelection} isMulti={false} />
        </div>

        {usuario === "LAVA TAP" ? (
          <>
            <StyledSubtitle color={colors.theme} size={24}>
              Carga de Incidencias por dia
            </StyledSubtitle>
            <div>
              <FaltasTablaLavatap />
            </div>

            <StyledSubtitle color={colors.theme} size={24}>
              Incidencia por fecha
            </StyledSubtitle>
            <div>
              <IncidentFormlavatap />
            </div>
          </>
        ) : (
          <>
            <StyledSubtitle color={colors.theme} size={24}>
              Carga de Incidencias por dia
            </StyledSubtitle>
            <div>
              <FaltasTabla />
            </div>

            <StyledSubtitle color={colors.theme} size={24}>
              Incidencia por fecha Supervisores
            </StyledSubtitle>
            <div>
              <IncidentFormS />
            </div>

            <StyledSubtitle color={colors.theme} size={24}>
              Incidencia por fecha
            </StyledSubtitle>
            <div>
              <IncidentForm />
            </div>
          </>
        )}

        <StyledSubtitle color={colors.theme} size={24}>
          Carga de Incidencias en Excel
        </StyledSubtitle>
        <div>
          <GuardiasFindeSemana />
        </div>
      </div>
    </div>
  );
};

export default IncidenciasPage;
