import React, { useState, useEffect, useCallback } from "react";
import "materialize-css/dist/css/materialize.min.css";
import M from "materialize-css";
import { StyledFormButton } from "../Styles";
import { hasWritePermissions } from "../apiCredentials";
import { useGetClientId } from "../../hooks/useGetUserClientId";
// (opcional) import SelectIdsComponent si lo usas externamente
// import SelectIdsComponent from "../dashboard/SelectIdsComponent";

// Pequeña función que valida el formato YYYY-MM-DD
const isValidYYYYMMDD = (dateStr) => {
  // Regex que fuerza el patrón YYYY-MM-DD, sin validar fechas imposibles (ej: 2025-13-40).
  const regex = /^\d{4}-\d{2}-\d{2}$/;
  return regex.test(dateStr);
};

function IncidentFormlavatap() {
  const canWrite = hasWritePermissions();
    
  const CLIENTID = useGetClientId();

  const [formData, setFormData] = useState({
    empId: "",
    nombre: "",
    fecini: "",
    fecfin: "",
    inciden: "",
  });

  useEffect(() => {
    M.AutoInit(); // Initialize all Materialize components
  }, []);

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    // Limpiar el campo 'nombre' si empId está vacío
    if (name === "empId" && value === "") {
      setFormData({
        ...formData,
        empId: "",
        nombre: "",
      });
    } else {
      setFormData({
        ...formData,
        [name]: value,
      });
    }
  };

  const fetchEmployeeName = useCallback(
    async (empId) => {
      const URL_STRING = `https://894bdij9ij.execute-api.us-east-1.amazonaws.com/licco/empleados/${CLIENTID}`;
      if (empId.length >= 4) {
        try {
          const response = await fetch(URL_STRING);
          const employees = await response.json();
          const employee = employees.find((emp) => emp.empnum === empId);

          if (employee) {
            setFormData((prevData) => ({
              ...prevData,
              nombre:
                employee.paterno +
                " " +
                employee.materno +
                " " +
                employee.nombre,
            }));
          } else {
            setFormData((prevData) => ({
              ...prevData,
              nombre: "No se encontró el ID",
            }));
          }
        } catch (error) {
          console.error("Error fetching employee name:", error);
          setFormData((prevData) => ({
            ...prevData,
            nombre: "Error fetching name",
          }));
        }
      }
    },
    [CLIENTID]
  );

  useEffect(() => {
    if (formData.empId) {
      fetchEmployeeName(formData.empId);
    }
  }, [formData.empId, fetchEmployeeName]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Validamos las fechas antes de crear la incidencia
    if (!formData.fecini || !isValidYYYYMMDD(formData.fecini)) {
      M.toast({
        html: "Fecha de inicio inválida o vacía (formato YYYY-MM-DD).",
        classes: "red",
      });
      return;
    }
    if (!formData.fecfin || !isValidYYYYMMDD(formData.fecfin)) {
      M.toast({
        html: "Fecha de fin inválida o vacía (formato YYYY-MM-DD).",
        classes: "red",
      });
      return;
    }

    const incInfo = {
      empId: formData.empId,
      fecini: formData.fecini,
      fecfin: formData.fecfin,
      inciden: formData.inciden,
    };

    await createIncidentsWA(incInfo);
  };

  const createIncidentsWA = async (incInfo) => {
    const clienteId = window.localStorage.getItem("idClienteSeleccionado");
    if (clienteId === null) {
      M.toast({
        html: "Selecciona un contrato antes de guardar.",
        classes: "red",
        displayLength: 4000,
      });
      return;
    }

    const URL_STRING =
      "https://894bdij9ij.execute-api.us-east-1.amazonaws.com/licco/incidencias";
    const record = {
      numempleado: incInfo.empId,
      client_id: clienteId,
      fechaDeInicio: incInfo.fecini, // <-- fecha que viene del form
      fechaDeFin: incInfo.fecfin, // <-- fecha que viene del form
      tipoIncidencia: incInfo.inciden,
    };

    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(record),
    };

    try {
      const response = await fetch(URL_STRING, options);
      const result = await response.json();
      if (
        result.error_code === "0" ||
        result.error_msg === "Incidencias actualizadas"
      ) {
        M.toast({ html: result.error_msg, classes: "green" });

        // Si deseas resetear solo al tener éxito:
        setFormData({
          empId: "",
          nombre: "",
          fecini: "",
          fecfin: "",
          inciden: "",
        });
      } else {
        M.toast({ html: result.error_msg, classes: "red" });
      }
    } catch (error) {
      console.error("Error:", error);
      M.toast({ html: "Error al enviar la incidencia.", classes: "red" }); // Error toast
    }
  };

  return (
    <div>
      <form onSubmit={handleSubmit}>
        {/* Fila para ID y nombre */}
        <div className="row">
          <div className="col s3">
            <div className="input-field">
              <input
                id="empId"
                type="text"
                name="empId"
                value={formData.empId}
                onChange={handleInputChange}
              />
              <label htmlFor="empId" className={formData.empId ? "active" : ""}>
                No Empleado
              </label>
            </div>
          </div>
          <div className="col s6">
            <div className="input-field">
              <input
                id="nombre"
                type="text"
                name="nombre"
                value={formData.nombre}
                onChange={handleInputChange}
                disabled // Deshabilitado para que el usuario no lo modifique manualmente
              />
              <label
                htmlFor="nombre"
                className={formData.nombre ? "active" : ""}
              >
                Nombre
              </label>
            </div>
          </div>
        </div>

        {/* Fila para fechas */}
        <div className="row">
          <div className="col s3">
            <div className="input-field">
              <input
                id="fecini"
                type="date"
                name="fecini"
                value={formData.fecini}
                onChange={handleInputChange}
                required
              />
              <label
                htmlFor="fecini"
                className={formData.fecini ? "active" : ""}
              >
                Fecha Inicio
              </label>
            </div>
          </div>
          <div className="col s3">
            <div className="input-field">
              <input
                id="fecfin"
                type="date"
                name="fecfin"
                value={formData.fecfin}
                onChange={handleInputChange}
                required
              />
              <label
                htmlFor="fecfin"
                className={formData.fecfin ? "active" : ""}
              >
                Fecha Fin
              </label>
            </div>
          </div>
        </div>

        {/* Fila para seleccionar incidencia y botón */}
        <div className="row">
          <div className="input-field col s5">
            <select
              id="inciden"
              name="inciden"
              value={formData.inciden}
              onChange={handleInputChange}
              className="browser-default"
              required
            >
              <option value="" disabled>
                Selecciona una opción
              </option>
              <option value="V">Vacaciones</option>
              <option value="INC">Incapacidad</option>
              <option value="F">Falta Justificada</option>
              <option value="D">Descanso</option>
              <option value="B">Baja</option>
            </select>
            <label htmlFor="inciden" className="active">
              Tipo de Incidencia
            </label>
          </div>
          <div style={{ padding: "13px" }}>
            {canWrite ? (
              <StyledFormButton type="submit">Insertar</StyledFormButton>
            ) : (
              <StyledFormButton type="button" disabled>
                Solo Lectura
              </StyledFormButton>
            )}
          </div>
        </div>
      </form>
    </div>
  );
}

export default IncidentFormlavatap;
