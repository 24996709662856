import React, { useEffect, useState, useCallback, useRef } from "react";
import { TabulatorFull as Tabulator } from "tabulator-tables";
import "tabulator-tables/dist/css/tabulator.min.css";
import { DateTime } from "luxon";
import { useGetClientId } from "../../hooks/useGetUserClientId";
import M from "materialize-css/dist/js/materialize.min.js";

const CountFTableWeek = ({ selectedIds }) => {
  const CLIENTID = useGetClientId();
  const [loading, setLoading] = useState(false); // Comienza en `false` ya que no debe cargar si no hay IDs
  const [data, setData] = useState([]);
  const tableRef = useRef(null);

  // Calcular las fechas para los últimos siete días
  const fecha_fin = DateTime.now().toFormat("yyyy-MM-dd");
  const fecha_inicio = DateTime.now().minus({ days: 7 }).toFormat("yyyy-MM-dd");

  const fetchData = useCallback(async () => {
    if (selectedIds.length === 0) {
      // Si no hay IDs seleccionados, no hacer la solicitud
      setData([]);

      return;
    }

    try {
      setLoading(true);

      const idsParam = selectedIds.join(",");
      const URL_GET = `https://894bdij9ij.execute-api.us-east-1.amazonaws.com/licco/asistencias/prenom/${fecha_inicio}/${fecha_fin}/${idsParam}`;

      const response = await fetch(URL_GET);
      if (!response.ok) {
       throw new Error("Failed to fetch data");
      }
      const result = await response.json();

      // Procesar datos para contar "F" en horas para cada empleado
      const processedData = result.reduce((acc, item) => {
        const existingEmployee = acc.find(
          (emp) => emp.employee_number === item.employee_number
        );
        if (existingEmployee) {
          existingEmployee.f_count += item.hora === "F" ? 1 : 0;
        } else {
          acc.push({
            employee_number: item.employee_number,
            nombre: item.nombre,
            f_count: item.hora === "F" ? 1 : 0,
          });
        }
        return acc;
      }, []);

      setData(processedData);
      console.log(processedData);
    } catch (error) {
      console.error("Error fetching data:", error);
      //M.toast({ html: `Error fetching data: ${error.message}`,        classes: "custom-toast",});
    } finally {
      setLoading(false);
    }
  }, [fecha_inicio, fecha_fin, selectedIds]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  useEffect(() => {
    if (tableRef.current && data.length > 0) {
      new Tabulator(tableRef.current, {
        data,
        layout: "fitColumns",
        pagination: "local",
        paginationSize: 20,
        initialSort: [{ column: "f_count", dir: "desc" }],
        columns: [
          { title: "#", field: "rownum", formatter: "rownum", width: 30 },
          {
            title: "Emp_Id",
            field: "employee_number",
            formatter: "plaintext",
            width: 60,
          },
          {
            title: "Nombre",
            field: "nombre",
            formatter: "plaintext",
            width: 150,
          },
          {
            title: "Faltas",
            field: "f_count",
            formatter: "plaintext",
            hozAlign: "center",
            width: 60,
          },
        ],
      });
    }
  }, [data]);

  return (
    <div>
      {selectedIds.length === 0 ? (
        // 1. Mostrar un mensaje de "Sin datos" o nada
        <p>No hay datos disponibles</p>
      ) : loading ? (
        // 2. Mostrar la barra de progreso si estás en loading
        <div className="progress">
          <div className="indeterminate"></div>
        </div>
      ) : (
        // 3. Renderizar el contenedor de la tabla
        <div ref={tableRef}></div>
      )}
    </div>
  );
};

export default CountFTableWeek;
