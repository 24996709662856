import PrenominaR from "../components/formatos/FormatoPrenomina";
import PrenominaLavaTap from "../components/formatos/FormatoPrenominaLavatap";
import Navbar from "../components/NavbarUsers";
import { StyledSubtitle, colors } from "../components/Styles";
import { consultNameEnterprise } from "../components/apiCredentials";
import SelectIdsComponent from "../components/dashboard/SelectIdsComponent";
import { useCallback, useState, useEffect } from "react";

const FormatPage = () => {
  const [selectedIds, setSelectedIds] = useState([]);
  const [usuario, setUsuario] = useState(null);
  // Memoriza la función handleIdsSelection
  const handleIdsSelection = useCallback((ids) => {
    setSelectedIds(ids);
    //console.log("Selected IDs:", ids); // Maneja los IDs seleccionados aquí
  }, []);

  useEffect(() => {
      // Simulación de obtener el usuario (reemplazar con API o contexto)
      //let listadecontratos = window.localStorage.getItem("listRaw");
      //console.log(listadecontratos);
      let usuarioLogueado = consultNameEnterprise(); // O contexto de autenticación
      console.log(usuarioLogueado);
      //usuarioLogueado = "GVIC";
      setUsuario(usuarioLogueado);
    }, []);


  return (
    <div>
      <Navbar />
      <div className="container">
        <StyledSubtitle color={colors.theme} size={24}>
          Formato de Prenómina
        </StyledSubtitle>
        <div className="row">
          <SelectIdsComponent onSelect={handleIdsSelection} isMulti={false} />
        </div>
        {/* Mostrar componente según el usuario */}
              {   usuario === "LAVA TAP" ? (
                <PrenominaLavaTap />
              )  : (
                <PrenominaR />
              )}

      </div>
    </div>
  );
};

export default FormatPage;
