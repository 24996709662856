// src/components/apiCredentials.js

// ClientID: si lo sigues usando para otras cosas
export const CLIENTID = "9";

/**
 * Determina si el usuario puede editar/escribir
 * (ejemplo: solo si role_name != "USER").
 *
 * @returns {boolean} true si tiene permisos de edición, false si está bloqueado
 */
export function hasWritePermissions() {
  // Lee el role_name de localStorage
  const roleName = window.localStorage.getItem("role_name");
  // Retorna false si es "USER", true en caso contrario
  //return true;
  return roleName !== "USER" && roleName !== "USER_COORDINADOR";
}

export function hasWritePermissionsEdit() {
  // Lee el role_name de localStorage
  const roleName = window.localStorage.getItem("role_name");
  // Retorna false si es "USER", true en caso contrario
  //return true;
  return roleName === "USER_COORDINADOR";
}

export function consultNameEnterprise() {
  const nameEnterprise = window.localStorage.getItem("listRaw");

  if (nameEnterprise.includes("LAVA TAP")) return "LAVA TAP";
  if (nameEnterprise.includes("GVIC")) return "GVIC";
  if (nameEnterprise.includes("LICCO")) return "LICCO";
  

  return null; // Retorna null si no coincide con ninguna opción
}

