import React, { useEffect, useState } from "react";
import Select from "react-select";

const SelectIdsComponent = ({
  onSelect,
  isMulti = false,
  context = "primary",
}) => {
  const [options, setOptions] = useState([]);
  const [selectedOptions, setSelectedOptions] = useState(isMulti ? [] : null);

  useEffect(() => {
    // Leemos ambas cadenas de localStorage
    const listRawFromStorage = window.localStorage.getItem("listRaw");
    const listRawContratoFromStorage =
      window.localStorage.getItem("listRawContrato");

    if (listRawFromStorage && listRawContratoFromStorage) {
      // Ejemplo:
      // listRaw ->        "17-BACK OFFICE,8-GVIC BIENESTAR"
      // listRawContrato -> "1084-BACK OFFICE,1076-GVIC BIENESTAR"

      // Parseamos los clientes (idCliente, nombre)
      const arrayClientes = listRawFromStorage.split(",").map((item) => {
        const [id, name] = item.split("-");
        return {
          idCliente: id.trim(),
          name: name.trim(),
        };
      });

      // Parseamos los contratos (idContrato, nombre)
      const arrayContratos = listRawContratoFromStorage
        .split(",")
        .map((item) => {
          const [id, name] = item.split("-");
          return {
            idContrato: id.trim(),
            name: name.trim(),
          };
        });

      // Emparejamos por nombre. El "BACK OFFICE" de clientes debe coincidir con el "BACK OFFICE" de contratos.
      const combined = arrayClientes.map((c) => {
        const match = arrayContratos.find((ctr) => ctr.name === c.name);
        return {
          // Para react-select, normalmente se usan { value, label }
          // Pero aquí guardamos idCliente en .value, name en .label, y adicionalmente idContrato
          value: c.idCliente,
          label: c.name,
          idContrato: match ? match.idContrato : null,
        };
      });

      setOptions(combined);

      // Configuración inicial de selección (por defecto)
      if (isMulti) {
        // Si es multi, seleccionamos todo
        setSelectedOptions(combined);
        // Notificamos al padre que se seleccionaron todos los clientes
        const allIds = combined.map((opt) => opt.value);
        onSelect?.(allIds);
      } else {
        // Si es single, no seleccionamos nada
        setSelectedOptions(null);
        onSelect?.([]);
      }
    } else {
      console.error("No se encontró la lista en localStorage.");
    }
  }, [onSelect, isMulti]);

  const handleChange = (selected) => {
    setSelectedOptions(selected);

    if (isMulti) {
      // Multi-select
      const selectedValues = (selected || []).map((option) => option.value);
      onSelect?.(selectedValues);
    } else {
      // Single-select
      const singleValue = selected ? selected.value : null;
      onSelect?.(singleValue ? [singleValue] : []);

      if (selected) {
        if (context === "primary") {
          window.localStorage.setItem("idClienteSeleccionado", selected.value);
        }

        window.localStorage.setItem(
          "idContratoSeleccionado",
          selected.idContrato || ""
        );
        window.localStorage.setItem(
          "nameContrato",
          `${selected.idContrato}-${selected.label}`
        );
      } else {
        window.localStorage.removeItem("idClienteSeleccionado");
        window.localStorage.removeItem("idContratoSeleccionado");
        window.localStorage.removeItem("nameContrato");
      }
    }
  };

  return (
    <div style={{ margin: "20px" }}>
      <Select
        id="ids-select"
        options={options}
        value={selectedOptions}
        onChange={handleChange}
        isMulti={isMulti}
        placeholder="Seleccionar contratos..."
        styles={{
          container: (base) => ({
            ...base,
            marginTop: "10px",
          }),
        }}
      />
    </div>
  );
};

export default SelectIdsComponent;
