import React, { useEffect, useState, useCallback, useRef } from "react";
import { TabulatorFull as Tabulator } from "tabulator-tables";
import "tabulator-tables/dist/css/tabulator.min.css";
import "materialize-css/dist/css/materialize.min.css";
import M from "materialize-css/dist/js/materialize.min.js";
import { DateTime } from "luxon";
import * as XLSX from "xlsx";
import "../tabulator-custom.css";
import { StyledExcelButton } from "../Styles";
import { hasWritePermissions } from "../apiCredentials";

const FaltasTabla = () => {
  const canWrite = hasWritePermissions();
  
  const CLIENTID = window.localStorage.getItem("idClienteSeleccionado");
  const [loading, setLoading] = useState(true);
  const [selectedDate, setSelectedDate] = useState(() =>
    DateTime.now().toFormat("yyyy-MM-dd")
  );
  const [table, setTable] = useState(null);
  const tableRef = useRef(null);

  const URL_POST =
    "https://894bdij9ij.execute-api.us-east-1.amazonaws.com/licco/incidencias";

  const fetchData = useCallback(async () => {
    const URL_GET = `https://894bdij9ij.execute-api.us-east-1.amazonaws.com/licco/asistencias/prenom/${selectedDate}/${selectedDate}/${CLIENTID}`;

    try {
      if (CLIENTID.length > 0) {
        const response = await fetch(URL_GET);
        if (!response.ok) {
          throw new Error("Failed to fetch data");
        }
        return await response.json();
      }
    } catch (error) {
      return [];
    }
  }, [selectedDate, CLIENTID]);

  const initializeTable = useCallback(
    (data) => {
      if (tableRef.current) {
        const newTable = new Tabulator(tableRef.current, {
          data,
          layout: "fitColumns",
          responsiveLayout: false,
          resizableColumns: true,
          pagination: "remote",
          paginationSize: 50,
          columns: [
            { title: "#", field: "rownum", formatter: "rownum", width: 30 },
            {
              title: "EmpID",
              field: "employee_number",
              width: "10px",
              headerFilter: "input",
              headerFilterParams: {
                placeholder: "Search...",
                minSearchChars: 2,
              },
              headerFilterPlaceholder: "Buscar...",
            },
            {
              title: "Nombre",
              field: "nombre",
              headerFilter: "input",
              headerFilterParams: { minSearchChars: 2 },
              headerFilterFunc: (headerValue, rowValue) =>
                rowValue.toLowerCase().includes(headerValue.toLowerCase()),
              width: "30px",
            },
            {
              title: "Fecha Baja",
              field: "fechabaja",
              width: "11px",
              editorParams: {
                format: "yyyy-MM-dd",
                verticalNavigation: "table",
              },
            },
            { title: "contrato", field: "contract_name", width: "12px" },
            {
              title: "Site",
              field: "e_attribute4",
              width: "11px",
              topCalc: "count",
              editor: "list",
              headerFilter: true,
              headerFilterParams: {
                values: {
                  XOLA: "XOLA",
                  "HOME OFFICE": "HOME OFFICE",
                  AÑIL: "AÑIL",
                  TRONCOSO: "TRONCOSO",
                  "": "TODOS",
                },
                clearable: true,
              },
            },
            { title: "Puesto", field: "e_attribute3", width: "11px" },
            {
              title: "Incidencia",
              field: "hora",
              editor: "list",
              hozAlign: "center",
              width: "13px",
              topCalc: "count",
              editorParams: {
                values: {
                  F: "Faltas Injustificadas",
                  INC: "Incapacidad",
                  V: "Vacaciones",
                  D: "Descanso",
                  FJ: "Faltas Justificadas",
                  HO: "Home Office",
                  PH: "Pago Horas",
                  "PS/G": "Permiso SIN Goce",
                  "PC/G": "Permiso CON Goce",
                  C: "Curva",
                  CAP: "Capacitación",
                  AA: "Acta Administrativa",
                },
              },
              headerFilter: true,
              headerFilterParams: {
                values: {
                  F: "Faltas Injustificadas",
                  INC: "Incapacidad",
                  V: "Vacaciones",
                  D: "Descanso",
                  FJ: "Faltas Justificadas",
                  HO: "Home Office",
                  PH: "Pago Horas",
                  "PS/G": "Permiso SIN Goce",
                  "PC/G": "Permiso CON Goce",
                  C: "Curva",
                  CAP: "Capacitación",
                  AA: "Acta Administrativa",
                  B: "Baja",
                  "": "",
                },
                clearable: true,
              },
            },
          ],
        });
        newTable.on("cellEditing", function (cell) {
          if (cell._alreadyToasted) {
            cell.cancelEdit();
            return;
          }
          // 1) Revisamos permiso
          if (!canWrite) {
            M.toast({
              html: "No tienes permiso para editar.",
              classes: "red",
            });
            cell._alreadyToasted = true;
            cell.cancelEdit();
            return;
          }
          // Se llama cuando Tabulator intenta poner la celda en modo edición
          const clienteId = window.localStorage.getItem(
            "idClienteSeleccionado"
          );

          // Si no existe idClienteSeleccionado, no permitimos la edición
          if (!clienteId) {
            // Verificamos si ya se mostró el toast en este intento de edición
            if (!cell._alreadyToasted) {
              M.toast({
                html: "Selecciona un contrato antes de guardar.",
                classes: "red",
                displayLength: 4000,
              });
              // Marcamos esta celda como “ya mostré el toast”
              cell._alreadyToasted = true;
            }

            // Cancelamos la edición para que no cambie el valor
            cell.cancelEdit();
          }
        });

        newTable.on("cellEditCancelled", function (cell) {
          // Cuando se cancela la edición (por cell.cancelEdit()),
          // eliminamos la marca para que si el usuario vuelve a intentar
          // editar en otro momento, se muestre el toast de nuevo (si aplica).
          //delete cell._alreadyToasted;
        });
        newTable.on("cellEdited", async function (cell) {
          // 1) Verificar si ya estamos procesando un "cellEdited" en esta celda
          if (cell._editInProgress) {
            // Si ya está “en proceso”, salimos para evitar llamadas duplicadas
            return;
          }
          // Marcamos la celda como “en proceso”
          cell._editInProgress = true;

          const updatedRow = cell.getRow().getData();
          const fieldName = cell.getField();
          const updatedValue = cell.getValue();

          // Aseguramos que haya un contrato (por si acaso)
          const clienteId = window.localStorage.getItem(
            "idClienteSeleccionado"
          );
          if (!clienteId) {
            // No hay contrato => revertimos y salimos
            cell.setValue(cell.getOldValue(), true);
            // Liberamos la marca para permitir futuras ediciones
            cell._editInProgress = false;
            return;
          }

          // Construimos el payload
          const payload = {
            numempleado: updatedRow.employee_number,
            nombre: updatedRow.nombre,
            client_id: clienteId,
            fechaDeInicio: selectedDate,
            fechaDeFin: selectedDate,
            tipoIncidencia: updatedRow.hora,
          };

                    try {
                      const options = {
                        method: "POST",
                        headers: { "Content-Type": "application/json" },
                        body: JSON.stringify(payload),
                      };
          
                      const response = await fetch(URL_POST, options);
                      if (!response.ok) {
                        // Error HTTP: revertimos y mostramos error
                        cell.setValue(cell.getOldValue(), true);
                        throw new Error(`HTTP error! status: ${response.status}`);
                      }
          
                      const responseData = await response.json();
                      // Validamos error_code
                      if (responseData.error_code === "0") {
                        // Caso OK
                        M.toast({
                          html: `INCIDENCIA CARGADA CORRECTAMENTE`,
                          classes: "green",
                        });
                      } else if (responseData.error_code === "15") {
                        // Caso OK
                        M.toast({
                          html: `INCIDENCIA ACTUALIZADA CORRECTAMENTE`,
                          classes: "green",
                        });
                      }else if (responseData.error_code === "-1") {
                        // Caso OK
                        M.toast({
                          html: `ERROR AL CARGAR INCIDENCIA`,
                          classes: "red",
                        });
                      } else {
                        // error_code ≠ 0 => revertimos y mostramos el mensaje
                        cell.setValue(cell.getOldValue(), true);
                        M.toast({
                          html: `Error: ${
                            responseData.error_msg || "NO SE PUDO ACTUALIZAR"
                          }`,
                          classes: "red",
                        });
                      }
                    }  catch (error) {
            console.error("Error saving data:", error);
            // Error en el fetch/catch => revertimos
            cell.setValue(cell.getOldValue(), true);
            M.toast({
              html: `Failed to update field: ${fieldName}\nError: ${error.message}`,
              classes: "red",
            });
          } finally {
            // 2) Quitamos la marca de "en proceso" al terminar
            cell._editInProgress = false;
          }
        });
        setTable(newTable);
      }
    },
    [CLIENTID, selectedDate, URL_POST]
  );

  useEffect(() => {
    const loadTable = async () => {
      setLoading(true);
      try {
        const data = await fetchData(); // Fetch data based on the selected date
        initializeTable(data);
      } catch (error) {
        console.error("Error loading table:", error);
        M.toast({
          html: `Error loading table: ${error.message}`,
          classes: "custom-toast",
        });
      } finally {
        setLoading(false);
      }
    };

    loadTable();
  }, [selectedDate, fetchData, initializeTable]);

  const handleDownload = async () => {
    if (!table) {
      console.error("Table instance not found");
      return;
    }

    try {
      const data = await table.getData(); // Await if it's a promise

      // Get column definitions for headers
      const columns = table.getColumnDefinitions();
      const headers = columns.map((col) => col.title);

      // Prepare the data for XLSX
      const wsData = [
        headers, // Add headers
        ...data.map((row) => columns.map((col) => row[col.field] || "")), // Add rows
      ];

      // Create a new workbook and worksheet
      const ws = XLSX.utils.aoa_to_sheet(wsData);
      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, `${selectedDate}`);

      // Generate Excel file
      const fileName = `Incidencias del día - ${selectedDate}.xlsx`; // Use selectedDate for filename
      XLSX.writeFile(wb, fileName);
    } catch (error) {
      console.error("Error generating XLSX file:", error);
      M.toast({
        html: `Error downloading file: ${error.message}`,
        classes: "custom-toast",
      });
    }
  };

  return (
    <>
      {loading && (
        <div className="progress">
          <div className="indeterminate"></div>
        </div>
      )}
      <div>
        <div className="input-field row">
          <div className="col s3">
            <input
              type="date"
              id="date-picker"
              value={selectedDate}
              onChange={(e) => setSelectedDate(e.target.value)}
            />
          </div>

          <div className="col s3">
            <StyledExcelButton onClick={handleDownload}>
              Descargar Excel
            </StyledExcelButton>
          </div>
        </div>

        <div ref={tableRef} id="incidents-table" className="tabulator"></div>
      </div>
    </>
  );
};

export default FaltasTabla;
