import React, { useState, useEffect, useRef } from "react";
import "materialize-css/dist/css/materialize.min.css";
import M from "materialize-css";
import { StyledFormButton } from "../Styles";
import { useGetClientId } from "../../hooks/useGetUserClientId";
import { read, utils } from "xlsx";
import { useForm } from "react-hook-form";
import axios from "axios";
import "tabulator-tables/dist/css/tabulator.min.css";
import { TabulatorFull as Tabulator } from "tabulator-tables";
import { hasWritePermissions } from "../apiCredentials";

function GuardiasFindeSemana() {
  const CLIENTID = useGetClientId();
  //console.log(CLIENTID);
  const { register, handleSubmit } = useForm();

  // Controla el contenido del archivo y la tabla
  const [fileContent, setFileContent] = useState(null);

  // Controla si la subida terminó para mostrar el botón "Recargar página"
  const [uploadCompleted, setUploadCompleted] = useState(false);

  // Referencia para renderizar Tabulator
  const tableRef = useRef(null);

  const canWrite = hasWritePermissions();
    

  // ------------------------------------------------------------------------------
  // 1) FUNCIÓN PRINCIPAL PARA PROCESAR EL ARCHIVO Y ENVIARLO AL ENDPOINT
  // ------------------------------------------------------------------------------
  const toJsonv4 = (data) => {
    const clienteId = window.localStorage.getItem("idClienteSeleccionado");
    console.log(clienteId);
    if (clienteId === null) {
      M.toast({
        html: "Selecciona un contrato antes de guardar.",
        classes: "red",
        displayLength: 4000,
      });
      return;
    }

    const reader = new FileReader();
    reader.readAsArrayBuffer(data.foto[0]);

    reader.onload = (event) => {
      const dataa = new Uint8Array(event.target.result);
      const libro = read(dataa, { type: "array" });
      const nombreHoja = libro.SheetNames[0];
      const hoja = libro.Sheets[nombreHoja];
      const dataJson = utils.sheet_to_json(hoja, {
        header: 1,
        raw: false,
        dateNF: "yyyy-mm-dd",
      });

      // La fila 1 (dataJson[1]) son los encabezados
      const headers = dataJson[1];
      const client = clienteId;

      // Preparar incidencias en columnas E y F
      const incidenciasE = [];
      const incidenciasF = [];

      const tipoIncidenciaIndexE = 4; // col E
      const tipoIncidenciaIndexF = 5; // col F

      for (let i = 2; i < dataJson.length; i++) {
        const row = dataJson[i];
        const numEmpleado = row[headers.indexOf("EMPID")];
        const nombreCompleto = row[headers.indexOf("NOMBRE COMPLETO")];
        const valueColumnE = row[tipoIncidenciaIndexE] || "";
        const valueColumnF = row[tipoIncidenciaIndexF] || "";

        // Columnas de fecha (arrancan en la 4)
        const dateColumns = headers.slice(4);
        dateColumns.forEach((dateHeader) => {
          const dateValue = row[headers.indexOf(dateHeader)];
          if (dateValue) {
            if (dateHeader === headers[tipoIncidenciaIndexE]) {
              incidenciasE.push({
                client_id: client,
                numempleado: numEmpleado?.toString() ?? "",
                nombre: nombreCompleto,
                fechaDeInicio: dateHeader,
                fechaDeFin: dateHeader,
                tipoIncidencia: valueColumnE,
              });
            }
            if (dateHeader === headers[tipoIncidenciaIndexF]) {
              incidenciasF.push({
                client_id: client,
                numempleado: numEmpleado?.toString() ?? "",
                nombre: nombreCompleto,
                fechaDeInicio: dateHeader,
                fechaDeFin: dateHeader,
                tipoIncidencia: valueColumnF,
              });
            }
          }
        });
      }

      // Unificamos incidencias en un array
      const allIncidences = [...incidenciasE, ...incidenciasF];
      const payloadAll = { incidencias: allIncidences };

      console.log("Payload unificado:", payloadAll);

      // Mostramos vista previa en la tabla
      setFileContent({ headers, data: dataJson.slice(2) });

      M.toast({ html: "Enviando incidencias...", classes: "green" });
      incidenciasMasivas(payloadAll);
    };
  };

  // ------------------------------------------------------------------------------
  // 2) FUNCIÓN PARA ENVIAR LAS INCIDENCIAS AL ENDPOINT
  // ------------------------------------------------------------------------------
  const incidenciasMasivas = async (jsonObject) => {
    const url =
      "https://894bdij9ij.execute-api.us-east-1.amazonaws.com/licco/incidencias/masivas";
    try {
      const response = await axios.post(url, jsonObject);
      console.log("Respuesta masiva:", response.data);

      M.toast({
        html: "El formato se cargó.",
        classes: "green",
      });

      // Limpiamos la tabla y habilitamos "Recargar"
      setFileContent(null);
      setUploadCompleted(true);
    } catch (error) {
      M.toast({ html: "Error al enviar la incidencia.", classes: "red" });
      console.log(error);

      // Si quieres, podrías setUploadCompleted(true) aunque haya error
    }
  };

  // Inicializar Materialize
  useEffect(() => {
    M.AutoInit();
  }, []);

  // ------------------------------------------------------------------------------
  // 3) LEER EL ARCHIVO ANTES DE ENVIAR
  // ------------------------------------------------------------------------------
  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.readAsArrayBuffer(file);

      reader.onload = (event) => {
        const dataa = new Uint8Array(event.target.result);
        const libro = read(dataa, { type: "array" });
        const nombreHoja = libro.SheetNames[0];
        const hoja = libro.Sheets[nombreHoja];
        const dataJson = utils.sheet_to_json(hoja, {
          header: 1,
          raw: false,
          dateNF: "yyyy-mm-dd",
        });

        const headers = dataJson[1];
        const data = dataJson
          .slice(2)
          .map((row) => {
            const obj = {};
            headers.forEach((header, index) => {
              obj[header] = row[index];
            });
            return obj;
          })
          .filter((row) => Object.values(row).some((value) => !!value));

        setFileContent({ headers, data });
      };
    }
  };

  // ------------------------------------------------------------------------------
  // 4) RENDERIZAR LA TABLA (Tabulator) PARA LA VISTA PREVIA
  // ------------------------------------------------------------------------------
  useEffect(() => {
    if (fileContent && tableRef.current) {
      const { headers, data } = fileContent;
      const columns = headers.map((key) => ({
        title: key,
        field: key.toString(),
      }));
      new Tabulator(tableRef.current, {
        data,
        layout: "fitColumns",
        columns,
      });
    }
  }, [fileContent]);

  // ------------------------------------------------------------------------------
  // 5) BOTÓN RELOAD
  // ------------------------------------------------------------------------------
  const handleReload = () => {
    window.location.reload();
  };

  // ------------------------------------------------------------------------------
  // 6) RENDER FINAL DEL COMPONENTE
  // ------------------------------------------------------------------------------
  return (
    <div>
      <div>
        {/* handleSubmit -> llama a toJsonv4 */}
        <form onSubmit={handleSubmit(toJsonv4)}>
          <input
            type="file"
            className="browser-default"
            {...register("foto", { required: true })}
            onChange={handleFileChange}
          />

          {/* Si uploadCompleted es true => "Recargar la página"
              De lo contrario => mostramos "Enviar" si canWrite, o "Solo Lectura" si no */}
          {uploadCompleted ? (
            <StyledFormButton
              type="button"
              style={{ margin: "20px" }}
              onClick={handleReload}
            >
              Recargar la página
            </StyledFormButton>
          ) : canWrite ? (
            <StyledFormButton type="submit" style={{ margin: "20px" }}>
              Enviar
            </StyledFormButton>
          ) : (
            <StyledFormButton type="button" style={{ margin: "20px" }} disabled>
              Solo Lectura
            </StyledFormButton>
          )}
        </form>
      </div>

      <div style={{ margin: "20px" }}>
        <p style={{ color: "orangered", fontSize: "24px" }}>
          Vista previa del archivo cargado
        </p>
        {fileContent && <div ref={tableRef} className="tabulator" />}
      </div>
    </div>
  );
}

export default GuardiasFindeSemana;
