import CalendarioF from "../components/calendario/calendarioFinal";
import CalendarioFLava from "../components/calendario/calendarioFinalLavatap";
import Navbar from "../components/NavbarUsers";
import { consultNameEnterprise } from "../components/apiCredentials";
import { StyledSubtitle, colors } from "../components/Styles";
import SelectIdsComponent from "../components/dashboard/SelectIdsComponent";
import { useCallback, useState, useEffect } from "react";

const CalendarioPage = () => {
  const [selectedIds, setSelectedIds] = useState([]);
  const [usuario, setUsuario] = useState(null);

  useEffect(() => {
    const usuarioLogueado = consultNameEnterprise(); // O contexto de autenticación
    console.log(usuarioLogueado);

    setUsuario(usuarioLogueado);
  }, []);


  const handleIdsSelection = useCallback((ids) => {
    setSelectedIds(ids);
    console.log("Selected IDs:", ids); // Maneja los IDs seleccionados aquí
  }, []);
  return (
    <div>
      <Navbar />
      <div className="container">
      <div className="row">
        <StyledSubtitle color={colors.theme} size={24}>
          Calendario Por Fechas
        </StyledSubtitle>
      </div>
      <div className="row">
        <SelectIdsComponent onSelect={handleIdsSelection} isMulti={false} />
      </div>
       {/* Mostrar componente según el usuario */}
       {   usuario === "LAVA TAP" ? (
                <CalendarioFLava />
              ) : (
                <CalendarioF />
              )}
      </div>
    </div>
  );
};

export default CalendarioPage;
